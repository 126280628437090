export default {
    cooperate: [
        {
            title: '新希望物业',
            subtitle: '四川港股上市物企代表！',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2025-01-13/1878627885765955585.png',
            time: '2024/12/27',
            url: 'https://mp.weixin.qq.com/s/-GKt1dhZMpRQ2ZE0sHPiQQ'
        },
        {
            title: '嘉茂城资',
            subtitle: '接轨浦东第一站',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2025-01-13/1878627900597014529.png',
            time: '2024/12/9',
            url: 'https://mp.weixin.qq.com/s/lt71oDrYu2ybxMDstnbcSQ?token=1997120587&lang=zh_CN'
        },
        {
            title: '中原科技城',
            subtitle: '中原大地起高峰！',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2025-01-13/1878627915172220930.png',
            time: '2024/11/29',
            url: 'https://mp.weixin.qq.com/s/LRkAWJc7NGlMwYpOtkxyog?token=42709704&lang=zh_CN'
        },
        {
            title: '新运城发集团',
            subtitle: '与城共新·运赢未来',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844304199415832578.png',
            time: '2024/9/26',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503450&idx=1&sn=212076a219f3b09ed944a3364de81797&chksm=c1d6355df6a1bc4b69f54bd1593245ba3c2f333c90135460f354eb2402a8a2af20f626862cee&token=710004980&lang=zh_CN#rd'
        },
        {
            title: '沈抚集团',
            subtitle: '助力沈抚改革创新示范区成为辽宁全面振兴的“突击队”和高质量发展的“先行区”',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844304291266895873.png',
            time: '2024/8/30',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503444&idx=1&sn=4234ba352755793c9e7ac790204b95ec&chksm=c1d63553f6a1bc450084360ba36840a7c6cf4b6992df8a440660be4b6a350ec44accc43ee524&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '唐山高新区',
            subtitle: '共同打造“唐朵”青年公寓品牌',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844304374779682818.png',
            time: '2024/8/9',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503418&idx=1&sn=749958250239f1afe4668df24ef9fae0&chksm=c1d6353df6a1bc2badad5cb7540799f387fdc3024cd5ec9923cfb4635edafcfea45dca416bee&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '沈阳中德开集团',
            subtitle: '助力沈阳高质量发展和新时代振兴',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844304448628793345.png',
            time: '2024/8/2',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503365&idx=1&sn=651d14027904d80efb880e37bde7eeef&chksm=c1d63502f6a1bc14f3f13ee4459bfbdc1f2dc813dfbf2281b1199cb7cd476d067e5be67f58ba&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '东楚优房供应链平台',
            subtitle: '重塑房地产产业链体系',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844304540756680706.png',
            time: '2024/7/12',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503349&idx=1&sn=d7cea37d67a12972a0b6678575265d1b&chksm=c1d636f2f6a1bfe4bd7b4645294cb80cfa0b702fdee66f5f6ab671a3528880def65675962c79&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '凯恒中心',
            subtitle: '助力朝阳门新时代建筑群',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844304637506691074.png',
            time: '2024/6/21',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503316&idx=1&sn=020aa3992d661843ea3a12f2e4040234&chksm=c1d636d3f6a1bfc579ac61bf8382cbfa6fe697258979ff807aaedc9074219f9717291ccd35c7&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '大兴区北臧村镇',
            subtitle: '构建专业的流动人口和出租房屋管理系统',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844304700232507393.png',
            time: '2024/6/19',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503310&idx=1&sn=aa62b2f4bb6feeeb0be32b11ef658acb&chksm=c1d636c9f6a1bfdfba9ce4fd3a65acae8bcbe80ff8da3a1f1427c0c0efe1ff84b72ab4e3f9ab&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '浪潮集团',
            subtitle: '共同发力数字经济',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844304779102199809.png',
            time: '2024/6/14',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503302&idx=1&sn=4f0afd8e267ec3b697713bb759c65824&chksm=c1d636c1f6a1bfd70d9a6094d9398e968b6268debb52f860f012ddf93ed0bfa5843dfa6919eb&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '保辉集团',
            subtitle: '立足长三角，辐射粤港澳',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844304937667862529.jpg',
            time: '2024/3/29',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503227&idx=1&sn=83117dc7713d922a35b4a92328b82a57&chksm=c1d6367cf6a1bf6a5d44b1daeda55b10b638bf8e4254a4c88d8dabfd87f35cae66092aea799e&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '讯美科技',
            subtitle: '共建创新金融、人工智能、服务贸易为标杆的高科技产业服务综合体',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844305013878366209.png',
            time: '2024/3/22',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503225&idx=1&sn=09ba9e6f06fe42a0df292a6e9341e54d&chksm=c1d6367ef6a1bf68f9c68d8a3be507362e8354c403eb5c87ca169bb7694026494dcb4e613cb4&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '深业集团',
            subtitle: '深圳本地智慧公寓项目',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844305083860328450.png',
            time: '2024/3/18',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503218&idx=1&sn=3c4174a9eb33c99036269f3833e256e8&chksm=c1d63675f6a1bf63a60d080612a25aafd1632769eabede1e4660e2160b7e8bca8e7e6d9eab76&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '鹿客科技',
            subtitle: '强强联合 | 鹿客科技与寓信科技达成战略合作',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844305163048787969.png',
            time: '2024/1/30',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503185&idx=1&sn=490f6c6d37943b5b757810d66f8f6e4a&chksm=c1d63656f6a1bf40876798b9641b8c955c2578337fdd0deb6b2d8ba696ffe982f1cd3888665c&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '贵阳住投',
            subtitle: '以“项目建设”“资产运营”为主线，以“服务群众”为纽带，为贵阳市民提供更优质的房屋和服务',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844305230321229825.png',
            time: '2024/1/26',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503165&idx=1&sn=5f97ed611449db8cd9ada2dac16ac9ee&chksm=c1d6363af6a1bf2c30a604a89c2fa4531d7ade8db3cebc61fdc0d842b80c062a941023cadb34&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '中国铁建',
            subtitle: '不忘初心，牢记使命，奋力前行！',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844305309765541890.png',
            time: '2023/12/29',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503156&idx=1&sn=5ea4d675e94543bb950a71ae657166d4&chksm=c1d63633f6a1bf2528833c28c166b830b5ab2662414fb05dce8566a2bff238171fde0f03cee9&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '上海新长宁',
            subtitle: '优化营商环境、助力招贤引才',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844305393362214914.png',
            time: '2023/12/22',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503120&idx=1&sn=997b7e403139ae7efa14242ec54245f0&chksm=c1d63617f6a1bf01504bb68e0122e690ae5adc9ab907ee085170b07680a400811977d11519c4&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '港汇101公寓',
            subtitle: '打造“邻里中心”+“天台花园”+“长租公寓”的复合型综合体',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844305594411982849.png',
            time: '2023/12/15',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503086&idx=1&sn=41b23ae61d8af3e5e54dd35c7c8ca008&chksm=c1d637e9f6a1beff6cd7e7466a285424220b072c3bbe1ac2f17f06e9463b698c1b9d5b496d60&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '凯迪仕',
            subtitle: '强强联合 | Kaadas凯迪仕与寓信科技达成战略合作',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844305670941253633.png',
            time: '2023/11/8',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503068&idx=1&sn=4537fabb1c51839c719804bf92bf63a7&chksm=c1d637dbf6a1becddcd614d7ef8c4c990150c2dd15f950ef6065283688f4b0e09e771bcbbcb5&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '厦门地铁',
            subtitle: '共建租住、社交、商业、娱乐及生活服务于一体的TOD综合体',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844310494915141633.png',
            time: '2023/10/24',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247502670&idx=1&sn=fa30c1be8391b6a45f44ae6048dbf79a&chksm=c1d63049f6a1b95f3661ac710f9f9dc97c414c78b1a52b778f404254969ac006da43402dc6a0&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '逸心商管',
            subtitle: '活跃城市，乐享生活',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844310599827267586.png',
            time: '2023/10/13',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247502075&idx=1&sn=686b51235b75cb30b6396340d54ce0ab&chksm=c1d633fcf6a1baea10abdde1f79dc7a13fc8bf25468bd912acbfcd73007fec6c89df6290a5f2&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '蓝色药谷',
            subtitle: '打造全链条、高质量、高效益的东北亚生物医药制造高地',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844310697990758402.png',
            time: '2023/9/28',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501996&idx=1&sn=c319284c8cc4e85a0f833407e5032249&chksm=c1d633abf6a1babde1cc8b07b7a2386f1d38cac009086bcdf95efa30123554b95de84bdf91e3&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '城盈家园',
            subtitle: '住房变革的引领者 城市更新的实践者',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844310771814703106.png',
            time: '2023/9/22',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501959&idx=1&sn=1e906fd75a884bd9e0e4399402d465ab&chksm=c1d63380f6a1ba96ac64e5c5fea4db5c562c432d6849b3d6f5939e19883ff24a06847ebfd18d&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '淮海科技城',
            subtitle: '科技兴城、项目立城、产业强城',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844310866459172865.png',
            time: '2023/9/8',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501841&idx=1&sn=4e821cf83a40f3df578e830cf4b853c0&chksm=c1d63316f6a1ba0087a5dd150a364e3518643e371dfcc7c678d0b5d7cc9f45835907f04d0a8b&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '白云城投',
            subtitle: '【城】载梦想 · 【投】筑辉煌',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844310938286628866.png',
            time: '2023/9/1',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501840&idx=1&sn=2efed054d940d5682f54762010f59947&chksm=c1d63317f6a1ba0144714adf89cdaacd1ac6d91d2590d89794e62c818bfc84af1d5232dd8ad6&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '中粮大悦城',
            subtitle: '大悦中国·赋美生活',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844311024743817218.png',
            time: '2023/8/25',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501839&idx=1&sn=99804017e8d9e5ee5db97cc983a91fc0&chksm=c1d63308f6a1ba1ef1a4bd4e43c88caa42d3084cc4b1c53ba5ca4b485cd7ad94b49909da1fc5&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '北京银行',
            subtitle: '京诚相伴·置慧生活',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844311112341856258.png',
            time: '2023/8/18',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501837&idx=1&sn=b93411f973b249d6e5375133da2d8b4b&chksm=c1d6330af6a1ba1c762f7a2081262f6404d4dd2c2830605d96093b5dd00fb3ac2b25be2b6dda&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '顺丰集团',
            subtitle: '打造全国性产业服务网络',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844311191073136642.png',
            time: '2023/8/2',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501836&idx=1&sn=158ee2daed90bf6e7fc18c7077bf1124&chksm=c1d6330bf6a1ba1df920c2ee169248557ca6bca46c82c427352bdeb4f512cf30bed239282b90&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '保利物业',
            subtitle: '大物业战略首倡者',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844311274468483074.png',
            time: '2023/7/28',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501835&idx=1&sn=04abae8b5c704b0bf6b788ec6adf89d1&chksm=c1d6330cf6a1ba1a2961a9c30093a490751b47b711417b43a97da62246d87e9abe63ba35ca0a&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '克拉玛依城寓',
            subtitle: '城市而生 "寓"见未来 石油之都 为"梦"加油',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844311345872314369.png',
            time: '2023/6/12',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501832&idx=1&sn=50ca434809e3a16b92f348a243a33a84&chksm=c1d6330ff6a1ba1900f00355d6f51d974424c3d43c8244baf8a6ba594372842a890adb3f490a&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '中兴集团',
            subtitle: '共建复合型高科技园区',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844311413547409409.png',
            time: '2023/3/25',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501823&idx=1&sn=36bd42444f9962709ba74a57602d2417&chksm=c1d63cf8f6a1b5ee9ce08dbcf5087115911628ee9f2cc1e2181ea131545ac570e48b2ad277b8&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '中国雄安集团',
            subtitle: '智慧公寓系统与智慧园区平台集成互联，打造一体化运营管理中心',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844311478496206849.png',
            time: '2022/11/22',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501814&idx=1&sn=a4093c1659412c7190e417fd8ed85609&chksm=c1d63cf1f6a1b5e702ee13f4ce13a9d1a46538aa404ad581baf3c0b67a65883653073143192c&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '中国联通',
            subtitle: '持续创造价值，助力中国联通长租公寓业务',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844311553280647170.png',
            time: '2022/5/13',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501805&idx=1&sn=8c3cc0b69cab1dd4e5e06fbfc50de8f5&chksm=c1d63ceaf6a1b5fcadf91116ee6e8c992b45a319f7b6342de44657da71be3c9535818c052b67&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '东龙控股',
            subtitle: '“筑巢引凤”打造人才聚集地',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844311623883366401.png',
            time: '2021/11/4',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501789&idx=1&sn=393cea73bfdbc2f5ad31ba89496d73eb&chksm=c1d63cdaf6a1b5cc37fa18cb8974e0e0a419afce46f62406aad7a686a1956fd794cc1e64eae7&token=1728425502&lang=zh_CN#rd'
        }
    ],
    dynamics: [
        {
            title: '寓信科技2025年1月产品更新丨短租模块',
            subtitle: '从房型、房态管理到账单管理，为短租模式提供全流程解决方案',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2025-01-13/1878629228006150145.jpg',
            time: '2025/1/2',
            url: 'https://mp.weixin.qq.com/s/TbfemO16G9fKRkE4S15rYw?token=321678172&lang=zh_CN'
        },
        {
            title: '寓信科技助力首届长租公寓发展论坛成功举办',
            subtitle: '增效赋能 供创共赢',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2025-01-13/1878629248268832770.jpg',
            time: '2024/11/29',
            url: 'https://mp.weixin.qq.com/s/2czUc7enXovFKdyYixjZ_g?token=42709704&lang=zh_CN'
        },
        {
            title: '寓信科技11月产品更新丨人才公寓',
            subtitle: '帮助人才公寓管理者降低管理成本，提升公寓收益',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-11-01/1852241575551729665.jpg',
            time: '2024/11/1',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503477&idx=1&sn=46cf5321a8a892a06388f3c0c908aa54&chksm=c1d63572f6a1bc64a0e393e027659a878e63516c9671c8072568a787b580717ab0054b4171ca&token=34954049&lang=zh_CN#rd'
        },
        {
            title: '寓信科技新业务 - 住房供应链平台',
            subtitle: '打造住房供应链平台新业务线！推动行业企业从线下分散经营，向线上集聚经营新模式转型升级',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844320141453631489.jpg',
            time: '2024/9/24',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503452&idx=1&sn=9ec11fc2ee3f7c47f72ccdc2d55c499e&chksm=c1d6355bf6a1bc4db4bf8664e53957509cef049deeddc8fe1bde54cb0c574718932023c58d33&token=448622549&lang=zh_CN#rd'
        }, {
            title: '与行业一起奔跑，五年再竖里程碑',
            subtitle: '五年的时间里，寓信科技由几个人的团队逐渐发展到200人规模，建成了覆盖产品研发、系统运维、客户服务、市场拓展、创新业务等多个部门的综合服务体系。',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844320324757299201.png',
            time: '2024/8/23',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503438&idx=1&sn=08b7bcdf5bb1ab7ecb7fcac506b0928c&chksm=c1d63549f6a1bc5feb18b396a4a9a949c4a81083c33dfffcff47966df2d624a8e0b0a2bb312b&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '寓信科技8月产品更新丨业主合同',
            subtitle: '更便捷地管理与业主的合同，调整和业主的租金',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844320461814571010.jpg',
            time: '2024/8/7',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503410&idx=1&sn=c0f3938ff0381f1b61fe038b16df6818&chksm=c1d63535f6a1bc23920ce84fa44125f6c2967570d8d9cb9363d5c9c00d964ff71784cb2f791e&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '寓信科技荣获“住房租赁MBI推荐服务商”',
            subtitle: '寓信科技凭借强劲的产品实力，久经市场考验的服务品质，荣获“2023年度住房租赁MBI推荐服务商”。',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844320574691680257.jpg',
            time: '2024/6/26',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503340&idx=1&sn=3767c12338565fc8fce8ed8832b114a2&chksm=c1d636ebf6a1bffdd7af7445339ca150be0bfc36b26414a3a508cef95582964152e73d677db4&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '寓信科技5月产品更新丨企业客户管理＆企业合同',
            subtitle: '完美解决企业客户（大客户）租房业务上的痛点与需求',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844320659685056513.jpg',
            time: '2024/5/10',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503295&idx=1&sn=e6b097341d820be9ec81c0a272b4466e&chksm=c1d636b8f6a1bfae4c36833cccbac7e253059b132d30c462dc8f1bc4e31a2bfaa02b13a3add6&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '寓信科技全面完成信创适配',
            subtitle: '寓信科技基于信创生态体系进行数字化建设，打造更安全更可靠的产品和服务。',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844320733114736642.jpg',
            time: '2024/4/26',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503258&idx=1&sn=085552791f2b4e5a23e08adb67727e9a&chksm=c1d6369df6a1bf8bdb1f3612a81a8104fffc5ad7388ef4831f96bfdf526594ffdcff1e64a2a1&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '“IIFAA产业数字化大会暨出行 & 租赁住房行业论坛”顺利召开',
            subtitle: '寓信科技张凯详解《助力住房租赁数字化升级》',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844320816417808385.jpg',
            time: '2024/4/20',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503243&idx=1&sn=3fea3f67feb597769d18e2b4c452eb52&chksm=c1d6368cf6a1bf9aa8e64382b7675062cfbd2933e1f7d952cb6b68bdbcd76c596d48d853eb72&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '寓信科技荣获“供应商最佳服务奖”',
            subtitle: '寓信科技在本次论坛荣获“2023年度供应商最佳服务奖”，同时寓信科技创始人兼CEO张凯也荣获“2023年度行业优秀人物”。',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844320889553887234.jpg',
            time: '2024/4/10',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503240&idx=1&sn=7dee6349b46e7ba8bd34d1b1cc4e52ec&chksm=c1d6368ff6a1bf99e20bfff2eb74255dcab278e206da83be53705ec9db2589d2ee1e0c91fd78&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '长租赛道的数字化答案',
            subtitle: '尽管公寓行业已逐渐认识到了数字化系统的优势，但行业专业数字化服务能力依旧不足。',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844331413876318210.png',
            time: '2024/2/5',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503195&idx=1&sn=01359194089dcdf302971afa716ab52f&chksm=c1d6365cf6a1bf4abd7193eb9a6e3b47ba9fd0fb2746dca6ed3f5784cff4ece4e7a9ada58a10&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '领航者说 | 寓信科技张凯：与行业一起奔跑',
            subtitle: '寓信科技，回首2023，展望2024',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844331489612865537.jpg',
            time: '2024/2/2',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503187&idx=1&sn=5e61bfe86b6a2fe2566fb294d6de14de&chksm=c1d63654f6a1bf423c11205698811325da37e791e235dfa21f5f042a7a11f27cc45971a0a1c8&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '寓信科技荣膺“2023资管新势力—年度服务创新典范企业”',
            subtitle: '寓信科技创始人兼CEO，城住数科总经理张凯受邀参加本次不动产资产管理峰会，并以“数字化如何赋能住房租赁行业发展”为题进行了主题演讲。',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844331569166229506.jpg',
            time: '2023/12/21',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247503110&idx=1&sn=d53450dfa9a1abfcdead0df97bb8144c&chksm=c1d63601f6a1bf17d395bf30e1b1810b79b302abaf7195123b428ceae0502632737fa80a6758&token=710004980&lang=zh_CN#rd'
        },
        {
            title: '寓信科技荣膺“长租公寓私有化定制服务企业TOP1”',
            subtitle: '寓信科技创始人 & 城住数科总经理 - 张凯，为在座超300位住房租赁行业国央企嘉宾，分享了《科技助力住房租赁高质量发展》。',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844331671897317377.png',
            time: '2023/12/21',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501834&idx=1&sn=763f9245e82a18f3438824df00ade270&chksm=c1d6330df6a1ba1bb550d8a46d42776276c9636acef95a10979a342a79efd9ff03f15530c3e3&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '寓信科技智能开票解决方案',
            subtitle: '全面缩短发票处理时间，轻松衔接全场景开票流程！',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844331757448536066.jpg',
            time: '2023/3/31',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501824&idx=1&sn=faeb1c870740cec545ee1b7237383c1d&chksm=c1d63307f6a1ba119c4ce00274ec32b2e861a0d623beed65ffbafad598b60842b1d22a47b874&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '新时代数字化管理工具—BI大屏',
            subtitle: '集数据可视化、智能分析、智能预警等功能于一体的综合管理平台',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844331833189277698.jpg',
            time: '2023/2/20',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501821&idx=1&sn=fa2aad6a25ed6240ab5aef71a6399d09&chksm=c1d63cfaf6a1b5ecf8be30ce455c6695c131c1d9c8edee03963714189a813e66c22c85a74a7e&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '寓信科技BI数据大屏特色功能',
            subtitle: '通过数据可视化，对重点信息进行展示，建立快速响应机制。',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844331908565114882.jpg',
            time: '2023/2/3',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501820&idx=1&sn=71abc3b3e65290c59ea18fc611be1f59&chksm=c1d63cfbf6a1b5ed91a88fcad13414fb273bac347fb7163a3f7771849e6d85f87b135fa05cd2&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '寓信PMS4.0会员体系特色功能',
            subtitle: '品牌公寓可以搭建会员体系，根据用户的行为划分不同的等级，对不同层级的用户提供差异化的服务',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844331995634671617.jpg',
            time: '2023/1/13',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501819&idx=1&sn=c4269b54976894a6e564dc101e0ba38c&chksm=c1d63cfcf6a1b5ea1f17ebb58d40f7c5117e00a02cd84960183411d7d74bc3237513ff68fc9d&token=1728425502&lang=zh_CN#rd'
        },
        {
            title: '2022年中国住房租赁行业年度报告（下）',
            subtitle: '国企为新增租赁资产获取主力军',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844332143752323073.jpg',
            time: '2023/2/3',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501820&idx=2&sn=df185797c387e25b71f493ff4365e7c0&chksm=c1d63cfbf6a1b5eddb271bf0b4546f85644a791e98e6b3400b0ae901b2b8a9b9963d672c46eb&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '2022年中国住房租赁行业年度报告（上）',
            subtitle: '租赁相关政策发布频繁，主要聚焦租赁供应和市场监管，保租房相关政策居多',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844332333775265794.jpg',
            time: '2023/1/13',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501819&idx=2&sn=161da34a352fa5b0842a471cb45a3fe3&chksm=c1d63cfcf6a1b5ea26834e5d710c3fa3e72b2e461120bc603160a6a39ede07416dded97657c0&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '寓信PMS4.0销售漏斗特色功能',
            subtitle: '随着品牌公寓的不断发展，销售思路已经慢慢从产品为中心转变为以客户为中心。',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844332567637073921.jpg',
            time: '2023/1/6',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501818&idx=1&sn=5878b0292a675db8abcde0520636b3d1&chksm=c1d63cfdf6a1b5eb26c66acdc13e769af2a8d889fd8345c3530b3e59d9e193cfa391f72bf2fe&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '寓信PMS4.0多经点位特色功能',
            subtitle: '结合公寓租客属性开发利用公寓公区，开设多种经营服务，丰富公寓的收入结构。',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844339029226627073.jpg',
            time: '2022/12/30',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501817&idx=1&sn=c11f3eea09dae43974324ba9b3b02138&chksm=c1d63cfef6a1b5e889c334cfa98ad67990c1f8bb41ee9bd13aedfb46117cf5c279bf835d3714&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '寓信PMS4.0全民营销特色功能',
            subtitle: '如何破解长租公寓获客难，将成为公寓盈利与否的关键因素。',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844339121664892929.jpg',
            time: '2022/12/9',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501816&idx=1&sn=d2ec69734b83c83f5aa514b80b0c061f&chksm=c1d63cfff6a1b5e97b7bf4f996328052574dd586bb1966595d56a285e5cba31164a4a4479d3a&token=1728425502&lang=zh_CN#rd'
        }, {
            title: '寓信PMS4.0支持一键转换房源出租形态',
            subtitle: '寓信PMS4.0系统中增加了一键转换功能，可以瞬间实现房间出租方式的转换。',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844332884957143041.jpg',
            time: '2022/12/2',
            url: 'https://mp.weixin.qq.com/s?__biz=MzkyNDUxMTg0OA==&mid=2247501815&idx=1&sn=e97235ee99b381a5cbfce2b5b77d1061&chksm=c1d63cf0f6a1b5e67e9da1cde750ec23a5efd2739994d13ef44b14e1601694346912295ce6c5&token=1728425502&lang=zh_CN#rd'
        }
    ],
    video: [
        {
            title: '1月更新-短租模块',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2025-01-13/1878629263397687298.jpg',
            time: '2025/1/2',
            url: 'https://mbd.baidu.com/newspage/data/videolanding?nid=sv_13430698707736342901'
        },
        {
            title: '11月更新-人才公寓',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-11-01/1852241589644591106.jpg',
            time: '2024/11/1',
            url: 'https://mbd.baidu.com/newspage/data/videolanding?nid=sv_6941823773591859089'
        },
        {
            title: '东楚优房APP',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844312810045120514.jpg',
            time: '2024/8/20',
            url: 'https://mbd.baidu.com/newspage/data/videolanding?nid=sv_8993755900058711861&sourceFrom=share'
        },
        {
            title: '8月更新 - 业主合同',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844312907176812546.jpg',
            time: '2024/8/7',
            url: 'https://mbd.baidu.com/newspage/data/videolanding?nid=sv_4211763691359437991&sourceFrom=share'
        },
        {
            title: '寓信人的一天 — 致奋斗者',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844312997291433986.jpg',
            time: '2024/3/15',
            url: 'https://mbd.baidu.com/newspage/data/videolanding?nid=sv_5205351987601025823&sourceFrom=share'
        },
        {
            title: '寓信公寓管理系统功能介绍 - 企业包租合同',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844313067772518402.jpg',
            time: '2023/8/11',
            url: 'https://mbd.baidu.com/newspage/data/videolanding?nid=sv_4527462212221299275&sourceFrom=share'
        },
        {
            title: '寓信PMS4.0全面更新',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844313154699468802.jpg',
            time: '2023/2/24',
            url: 'https://mbd.baidu.com/newspage/data/videolanding?nid=sv_7454591463028649757&sourceFrom=share'
        },
        {
            title: '长租公寓如何转型？',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844313228858957826.jpg',
            time: '2022/12/30',
            url: 'https://mbd.baidu.com/newspage/data/videolanding?nid=sv_10824374634795923767&sourceFrom=share'
        },
        {
            title: '寓信科技全面助力保障性租赁住房企业数字化转型',
            img: 'https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2024-10-10/1844313300246011905.jpg',
            time: '2022/11/25',
            url: 'https://mbd.baidu.com/newspage/data/videolanding?nid=sv_7227139524054958214&sourceFrom=share'
        }
    ]
}