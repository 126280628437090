<template>
  <div class="container">
    <div class="top">
      <div class="top_con">
        <div class="top_col span1">
          <dl>
            <dt>我们的愿景</dt>
            <dd>做最值得信任的长租公寓系统定制服务商</dd>
            <dt>我们的使命</dt>
            <dd>科技助力居住行业发展</dd>
          </dl>
        </div>
        <div class="top_col span2">
          <dl>
            <dt>联系我们</dt>
            <dd>联系电话：19925235826</dd>
            <dd>业务合作：bp@yxpms.com</dd>
            <dd>简历投递：hr@yxpms.com</dd>
          </dl>
        </div>
        <div class="top_col span3">
          <dl>
            <dt>公司地址</dt>
            <dd>北京总部：北京市石景山区点石商务公园6号楼9层</dd>
            <dd>深圳总部：龙岗区坂田新天下工业城百瑞达大厦A2座2层</dd>
          </dl>
        </div>
        <div class="top_col span4">
          <a-row class="wechat" type="flex" justify="space-around">
            <a-col class="items" :span="12">关注我们</a-col>
            <a-col class="items items2" :span="12">扫码合作</a-col>
          </a-row>
        </div>
      </div>
    </div>
    <div class="link">
      <span>友情链接</span>
      <a href="http://www.yuxintop.cn" target="_blank">寓信深圳</a>
      <b>|</b>
      <a href="https://www.yxpms.com" target="_blank">寓信北京</a>
      <b>|</b>
      <a href="https://www.mingzhicloud.cn" target="_blank">住房供应链平台</a>
    </div>
    <div class="bottom">
      <p v-if="!type">
        Copyright © 寓信（北京）网络科技有限公司 All Rights Reserved
        工信部备案号：京ICP备案2021008418
      </p>
      <p v-else>
        Copyright © 寓信（深圳）网络科技有限公司 All Rights Reserved
        工信部备案号：粤ICP备2023134666号-1
      </p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'PublicFooter',
  data () {
    return {
      type: 0
    }
  },
  mounted () {
    if (location.includes('yuxintop')) {
      this.type = 1
    }
  }
}
</script>

<style lang="less" scoped>
.top {
  padding: 60px 20px 0;
  box-sizing: border-box;
  background-color: #242525;
  height: 255px;
  .top_con {
    width: 1200px;
    margin: 0 auto;
    color: #fff;
    overflow: hidden;
    .top_col {
      float: left;
    }
    .span1 {
      width: 30%;
    }
    .span2 {
      width: 25%;
    }
    .span3 {
      width: 25%;
      dd {
        width: 200px;
      }
    }
    .span4 {
      width: 20%;
      padding: 0 10px;
    }
    dt {
      font-size: 16px;
      margin-bottom: 12px;
    }
    dd {
      color: #999;
      font-size: 14px;
      margin-bottom: 12px;
    }
    .wechat {
      .items {
        color: #eee;
        width: 90px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        position: relative;
        top: 120px;
        &:after {
          content: '';
          width: 90px;
          height: 90px;
          background: url('../assets/image/QRCode1.jpeg') no-repeat center;
          background-size: contain;
          position: absolute;
          top: -100px;
          left: 0;
        }
        &.items2:after {
          background-image: url('../assets/image/QRCode2.png');
        }
      }
    }
  }
}
.link {
  padding: 4px;
  text-align: center;
  background-color: #242525;
  color: #999999;
  span {
    margin-right: 14px;
  }
  a {
    color: #999999;
    &:hover {
      text-decoration: underline;
    }
  }
  b {
    margin: 0 8px;
    font-weight: normal;
  }
}
.bottom {
  p {
    line-height: 20px;
    text-align: center;
    color: #999;
    background-color: #292929;
    margin: 0;
    padding: 10px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding-bottom: 81px;
  }
  .top {
    height: 620px;
    .top_con {
      width: 100%;
      .top_col {
        width: 100%;
      }
      .span3 dd {
        width: 100%;
      }
      .span4 {
        width: 220px;
        height: 200px;
      }
    }
  }
}
</style>
