<template>
  <div class="page">
    <div class="top">
      <h3></h3>
      <h1>住房供应链平台</h1>
      <h4>构建房地产发展新模式，促进房地产高质量发展</h4>
      <div class="btn_wrap">
        <a-space size="large">
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
          >
            <div class="button btn-size">预约演示</div>
          </a>
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
          >
            <div class="button plain btn-size">免费试用</div>
          </a>
        </a-space>
      </div>
    </div>
    <div class="part p-b-0">
      <h2>住房供应链平台</h2>
      <h4>推动行业企业从线下分散经营，向线上集聚经营新模式转型升级</h4>
      <a-row :gutter="[24, { xs: 12, sm: 12, md: 24 }]">
        <a-col :span="24" :md="24" :sm="24" :xs="24">
          <img
            class="img-top2"
            src="https://yuxin-saas.oss-cn-shenzhen.aliyuncs.com/saas/2025-01-13/1878625307380486146.png"
            alt=""
          />
        </a-col>
      </a-row>
    </div>
    <div class="part">
      <h2>优房服务</h2>
      <h4 class="text-digital" v-if="screenWidth > 768">
        集成一站式优房服务，不仅具备一般线上找房产品的功能，还通过与相关部门的协作，延伸至房产交易的网签、备案、过户状态的及时跟踪等后续环节，真正做到“一网通办”
      </h4>
      <h4 class="text-digital" v-else>
        不仅具备一般线上找房产品的功能，还延伸至房产交易的网签、备案、过户状态的及时跟踪等后续环节，真正做到“一网通办”
      </h4>
      <supply-case />
    </div>
    <!--<div class="tab">-->
    <div class="part" :class="{'p-t-0':screenWidth <= 768}">
      <h2 v-if="screenWidth > 768">城市服务、适老改造、以旧换新</h2>
      <h4 class="text-digital" v-if="screenWidth > 768">
        城市服务持续优化，聚焦适老改造，通过以旧换新的方式，为居民打造更加宜居的<br />生活环境，构建更加和谐宜居的城市环境
      </h4>
      <!--<h4 class="text-digital" v-else>
        城市服务持续优化，聚焦适老改造，通过以旧换新的方式，为居民打造更加宜居的生活环境
      </h4>-->

      <suitable-elderly />
    </div>
    <!--</div>-->
    <div class="part m-b-t">
      <h2>金融服务</h2>
      <h4>
        打造“N+N+N”供应链金融模式，<br />
        形成“互联网+供应链金融第三方共享服务平台”
      </h4>
      <a-row :gutter="[24, { xs: 12, sm: 12, md: 20 }]">
        <a-col :span="8" :md="8" :sm="24" :xs="24">
          <div class="finance bg-1">
            <div class="icon icon1"></div>
            <div class="text-main">
              <div class="title">个人金融服务</div>
              <div class="text">
                为市民提供消费贷款、信用卡申请等服务，满足个人资金需求
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="8" :md="8" :sm="24" :xs="24">
          <div class="finance bg-2">
            <div class="icon icon2"></div>
            <div class="text-main">
              <div class="title">企业融资服务</div>
              <div class="text">
                支持中小企业进行融资申请、协助解决企业发展的资金问题
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="8" :md="8" :sm="24" :xs="24">
          <div class="finance bg-3">
            <div class="icon icon3"></div>
            <div class="text-main">
              <div class="title">保险服务</div>
              <div class="text">
                集成保险业务，一站式覆盖生活各方面，为用户构筑全面保障
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="part m-b-t part-bg-2">
      <h2>家装集采</h2>
      <h4 v-if="screenWidth > 768">
        遵循生态环保与质量标准，推动绿色家装促进家居行业健康发展，<br />通过集中采购降低成本，居民享受更优惠的装修价格
      </h4>
      <h4 v-else>
        遵循生态环保与质量标准，推动绿色家装<br />促进家居行业健康发展
      </h4>

      <div class="box">
        <a-row :gutter="[24, { xs: 12, sm: 12, md: 20 }]">
          <a-col :span="8" :md="8" :sm="24" :xs="24">
            <div class="finance bg-f">
              <div class="icon icon4"></div>
              <div class="text-main">
                <div class="title">在线家装商城</div>
                <div class="text">
                  在线平台便捷挑选，高效完成商品采购流程，丰富选择一键下单
                </div>
              </div>
            </div>
          </a-col>
          <a-col :span="8" :md="8" :sm="24" :xs="24">
            <div class="finance bg-f">
              <div class="icon icon5"></div>
              <div class="text-main">
                <div class="title">一站式服务</div>
                <div class="text">
                  家装平台承接采购，专业高效完成装修物料选购，一站式省心服务
                </div>
              </div>
            </div>
          </a-col>
          <a-col :span="8" :md="8" :sm="24" :xs="24">
            <div class="finance bg-f">
              <div class="icon icon6"></div>
              <div class="text-main">
                <div class="title">装修案例分享</div>
                <div class="text">
                  发布精美装修案例，分享前沿家装理念，打造优质生活空间
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="part">
      <h2>BI数据驾驶舱</h2>
      <h4>用数据降低交易成本,盘活存量资产</h4>
      <div class="case_wrap">
        <div v-if="screenWidth <= 768">
          <img src="@/assets/image/HousingSupply/bi.png" alt="" />
        </div>

        <div class="left-text">
          <h3>供需精准匹配</h3>
          <div>
            {{
              screenWidth > 768
                ? '执行“以人定需、以需定供、供需匹配”的策略，实现资源有效利用，提高市场效率，规范行业秩序、制定购房政策。'
                : '执行“以人定需、以需定供、供需匹配”的策略，实现资源有效利用，规范行业秩序、制定购房政策。'
            }}
          </div>
          <h3>数据平台辅助决策</h3>
          <div>
            {{
              screenWidth > 768
                ? '以搭建平台业务为基础、深耕BI数据分析，从人、货、场三个场景出发，用真实的交易数据来指导房地产市场的供需匹配决策。'
                : '以搭建平台业务为基础，从人、货、场三个场景出发，用真实的交易数据来指导房地产市场的供需匹配决策。'
            }}
          </div>
        </div>
        <div v-if="screenWidth > 768">
          <img src="@/assets/image/HousingSupply/bi.png" alt="" />
        </div>
      </div>
    </div>
    <div class="part">
      <h2>成功案例</h2>
      <h4>东楚优房供应链APP</h4>
      <div class="case_wrap">
        <div>
          <!--<img src="@/assets/image/ProductRent/case.png" alt="" />-->
          <video
            src="@/assets/video/dc.mp4"
            class="video"
            loop
            muted
            autoplay
            preload
            controls="controls"
            x-webkit-airplay="true"
            airplay="allow"
            webkit-playsinline="true"
            playsinline="true"
          ></video>
        </div>
        <div>
          <h1 v-if="screenWidth > 768">寓信科技携手东楚投资集团构建一体化住房供应链数字服务平台</h1>
          <p>
            寓信科技携手东楚投资集团围绕以需求牵引供给，以供给创造需求，政企联合打造可持续、数字化、智能型、服务性房地产综合性平台--东楚优房供应链平台APP。
          </p>
          <span> </span>
        </div>
      </div>
    </div>
    <div class="part bottom_wrap">
      <h2>更懂企业的管理痛点，陪伴客户走向成功</h2>
      <h4>专家团队实践经验丰富，提供一站式咨询服务</h4>
    </div>
    <div class="bottom_bg">
      <div class="exhibition_wrap">
        <div class="exhibition_item">
          <img src="@/assets/image/ProductApartment/bottom1.png" alt="" />
          <div>
            <h4>售前咨询</h4>
            <p>
              资深的行业方案专家
              <br />
              根据您的诉求设计解决方案
            </p>
          </div>
        </div>
        <div class="exhibition_item">
          <img src="@/assets/image/ProductApartment/bottom2.png" alt="" />
          <div>
            <h4>实施上线</h4>
            <p>
              专业的实施团队，根据企业的业务场景
              <br />
              进行安装部署与系统配置
            </p>
          </div>
        </div>
        <div class="exhibition_item">
          <img src="@/assets/image/ProductApartment/bottom3.png" alt="" />
          <div>
            <h4>售后及运营</h4>
            <p>
              可靠的售后及运营团队，提供使用培训与
              <br />
              案例分享，持续为企业迭代产品和服务
            </p>
          </div>
        </div>
      </div>
      <a href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s" target="_blank">
        <div class="button btn-size exper">立即咨询</div>
      </a>
    </div>
  </div>
</template>

<script>
import SupplyCase from '@/components/SupplyCase.vue'
import SuitableElderly from '@/components/SuitableElderly.vue'

SuitableElderly

export default {
  name: 'HousingSupply',
  components: {
    SupplyCase,
    SuitableElderly
  },
  data () {
    return {
      screenWidth: document.body.clientWidth,
      tabList: ['居民信息管理', '房东端租房管理', '租客服务平台'],
      tabActive: 0
    }
  },
  methods: {},
  created () {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  }
}
</script>

<style scoped lang="less">
h1,
h2,
h3,
h4,
p {
  margin-bottom: 0;
}
.button {
  background: #2267f6;
  border-radius: 2px;
  color: #ffffff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.plain {
  border: 2px solid #2267f6;
  background-color: transparent;
  color: #fff;
  border-radius: 2px;
}
.btn-size {
  height: 50px;
  width: 160px;
  font-size: 18px;
}
.top {
  height: 550px;
  padding-top: 70px;
  background: url('../assets/image/HousingSupply/bg-top.png') no-repeat center;
  background-size: cover;
  background-position: 100% 100%;
  h3 {
    width: 1200px;
    margin: 0 auto;
    padding-top: 100px;
    font-size: 26px;
    color: #ffffff;
  }
  h1 {
    color: #fff;
    width: 1200px;
    margin: 0 auto;
    font-size: 44px;
    font-weight: bold;
  }
  h4 {
    color: #fff;
    width: 1200px;
    margin: 0 auto;
    font-size: 16px;
    padding-top: 20px;
  }
  .btn_wrap {
    width: 1200px;
    margin: 31px auto;
  }
}
.part {
  width: 1200px;
  margin: 0 auto;
  padding: 54px 0;
  text-align: center;
  .type-list {
    padding: 0 50px;
  }
  h2 {
    font-weight: 700;
    font-size: 36px;
    color: #030d1e;
  }
  h4 {
    margin-bottom: 40px;
    font-size: 20px;
    color: #545558;
    font-weight: 400;
  }
  .bottom_wrap {
    padding-bottom: 35px;
  }
  .product_item {
    display: flex;
    padding: 42px 32px;
    border: 1px solid #ffffff;
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
    img {
      height: 56px;
      margin-right: 29px;
    }
    h4 {
      font-size: 24px;
      margin-bottom: 7px;
      font-weight: bold;
      text-align: left;
    }
    p {
      font-size: 16px;
      color: #808082;
      text-align: left;
    }
  }
}
.m-b-t {
  h4 {
    margin-bottom: 20px;
  }
}

.digital_wrap {
  margin: 10px;
  img {
    width: 100%;
  }
}
.tab {
  background-color: #f5f6fa;
}
.tab_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4edfc;
  .tab_item {
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 20px;
    cursor: pointer;
    width: 200px;
  }
  .check {
    position: relative;
  }
  .check::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 4px;
    background: #0060ff;
  }
}
.tab_content {
  margin-top: 54px;
  display: flex;
  align-items: center;
  & > div {
    flex: 1;
  }
  .cockpit_text {
    text-align: left;
    &.right {
      text-align: right;
    }
    h3 {
      color: #030d1e;
      font-size: 20px;
      font-weight: 700;
      margin: 20px 0 0;
    }
    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      color: #607499;
      margin: 10px 0 20px;
    }
  }
  .img-wrap {
    margin: 0 60px;
    &.tab3 {
      margin-top: 14px;
      img {
        width: 60%;
      }
    }
    img {
      width: 100%;
    }
  }
}
.case_wrap {
  display: flex;
  align-items: center;
  text-align: left;
  width: 1000px;
  margin: 0 auto;
  & > div {
    flex: 1;
    padding: 0 20px;
  }
  .left-text {
    width: 400px;
    text-align: left;
    & > h3 {
      height: 29px;
      margin: 29px 0 6px;
      font-weight: 700;
      font-size: 20px;
      color: #030d1e;
      line-height: 29px;
      text-align: justify;
    }
    & > div {
      width: 383px;
      height: 78px;
      font-weight: 400;
      font-size: 16px;
      color: #545558;
      line-height: 26px;
      text-align: justify;
    }
  }
  video {
    width: 478px;
    height: 269px;
  }
  img {
    width: 599px;
    height: 342px;
  }
  h1 {
    font-size: 20px;
  }
  p {
    margin: 20px 0 30px;
    font-size: 14px;
    color: #607499;
  }
  span {
    font-size: 14px;
    color: rgba(3, 13, 30, 0.26);
  }
}
.exper {
  margin: 0 auto;
  margin-top: 55px;
}
.bottom_bg {
  height: 554px;
  width: 100%;
  background: url('../assets/image/ProductApartment/bottom_bg.png') no-repeat
    center;
  background-size: cover;
  padding-top: 64px;
  text-align: center;
  .exhibition_wrap {
    padding: 0 20px 40px 90px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .exhibition_item {
      text-align: center;
      h4 {
        color: #3d3d3d;
        font-size: 20px;
        margin: 10px 0;
      }
      img {
        height: 100px;
        width: 100px;
      }
      p {
        font-size: 16px;
        color: #545558;
      }
    }
  }
}
.p-b-0 {
  padding-bottom: 0;
}
.img-top2 {
  width: 739px;
  height: 504px;
  margin: 0 auto;
}
.text-digital {
  width: 792px;
  margin: 0 auto;
}
.finance {
  width: 386px;
  height: 178px;
  padding: 40px 36px;
  display: flex;
  .icon {
    width: 46px;
    height: 46px;
    margin-right: 8px;
  }
  .text-main {
    width: 250px;
    .title {
      height: 29px;
      font-weight: 700;
      font-size: 20px;
      color: #ffffff;
      line-height: 29px;
      text-align: left;
      margin: 8px 0;
    }
    .text {
      width: 250px;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      line-height: 26px;
      text-align: justify;
    }
  }
  .icon4 {
    background: url('../assets/image/HousingSupply/finance-icon4.png') no-repeat;
    background-size: contain;
  }
  .icon5 {
    background: url('../assets/image/HousingSupply/finance-icon5.png') no-repeat;
    background-size: contain;
  }
  .icon6 {
    background: url('../assets/image/HousingSupply/finance-icon6.png') no-repeat;
    background-size: contain;
  }
}
.bg-1 {
  background: url('../assets/image/HousingSupply/finance1.png') no-repeat;
  background-size: cover;
  .icon1 {
    background: url('../assets/image/HousingSupply/finance-icon1.png') no-repeat;
    background-size: cover;
  }
}
.bg-2 {
  background: url('../assets/image/HousingSupply/finance2.png') no-repeat;
  background-size: cover;
  .icon2 {
    background: url('../assets/image/HousingSupply/finance-icon2.png') no-repeat;
    background-size: cover;
  }
}
.bg-3 {
  background: url('../assets/image/HousingSupply/finance3.png') no-repeat;
  background-size: cover;
  .icon3 {
    background: url('../assets/image/HousingSupply/finance-icon3.png') no-repeat;
    background-size: cover;
  }
}
.part.m-b-t.part-bg-2 {
  width: 100vw;
  background: url('../assets/image/HousingSupply/bg-2.png') no-repeat;
  background-size: cover;
  .box {
    width: 1200px;
    margin: 0 auto;
    .title {
      color: #030d1e;
    }
    .text {
      color: #545558;
    }
  }
}
.finance.bg-f {
  text-align: center;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .page {
    width: 100vw;
    overflow: hidden;
  }
  .img-top2 {
    width: 314px;
    height: 210px;
  }
  .text-digital {
    width: 314px;
    margin: 8px auto 15px;
  }
  .btn-size {
    height: 29px;
    width: 72px;
    font-size: 12px;
    font-weight: 700;
  }
  .top {
    height: 200px;
    background: url('../assets/image/HousingSupply/bg-top.png') no-repeat center;
    background-size: cover;
    background-position: 100% 100%;
    margin-top: 50px;
    padding: 0 12px;
    h3 {
      padding: 60px 0 4px;
      font-size: 16px;
    }
    h1 {
      height: 22px;
      font-weight: 700;
      font-size: 16px;
      color: #ffffff;
      line-height: 22px;
      text-align: left;
      margin-bottom: 5px;
    }
    h4 {
      height: 17px;
      font-weight: 500;
      font-size: 12px;
      color: #ffffff;
      line-height: 17px;
      text-align: left;
      padding: 0;
    }
  }
  .part {
    width: 100%;
    padding: 14px 0;
    h2 {
      font-size: 16px;
    }
    h4 {
      font-size: 12px;
      margin-bottom: 10px;
    }
    .bottom_wrap {
      padding-bottom: 24px;
    }
    .product_item {
      margin: 0 12px;
      padding: 20px 24px;
      img {
        height: 48px;
        margin-right: 24px;
      }
      .item_left {
        h4 {
          font-size: 14px;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
  .tab_wrap {
    justify-content: space-evenly;
    flex-wrap: wrap;
    .tab_item {
      width: 128px;
      font-size: 12px;
      margin-top: 12px;
    }
    .check::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: #0060ff;
    }
  }
  .tab_content {
    margin: 0 12px;
    display: block;
    .cockpit_text {
      margin: 0 auto;
      h3 {
        font-size: 14px;
        text-align: center;
        margin-top: 20px;
      }
      p {
        font-size: 12px;
        text-align: center;
        line-height: 1.5;
      }
    }
    .img-wrap {
      width: 50%;
      margin: 0 auto;
      &.tab3 {
        width: 100%;
        img {
          width: 80%;
        }
      }
    }
  }
  .case_wrap {
    display: initial;
    margin: 0 12px;
    img {
      width: 100%;
      padding: 0 28px;
      height: 183px;
      margin-bottom: 24px;
    }
    video {
      width: 100%;
      //padding: 0 16px;
      height: 192px;
    }
    p {
      margin: 10px 0 20px;
      width: 343px;
      font-weight: 400;
      font-size: 12px;
      color: #545558;
      line-height: 18px;
      text-align: justify;
    }
    h1 {
      height: 17px;
      font-weight: 700;
      font-size: 12px;
      color: #030d1e;
      line-height: 17px;
      text-align: justify;
      margin: 24px 0 6px;
    }
  }
  .exper {
    margin-top: 24px;
  }
  .bottom_bg {
    height: 500px;
    width: 100%;
    background: url('../assets/image/ProductApartment/bottom_bg_mobile.png')
      no-repeat center;
    background-size: cover;
    padding-top: 0;
    text-align: center;
    .exhibition_wrap {
      width: 100%;
      padding: 0 40px;
      flex-wrap: wrap;
      .exhibition_item {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 52px;
        img {
          height: 47px;
          width: 47px;
          margin-right: 16px;
        }
        h4 {
          font-size: 14px;
          text-align: left;
          font-weight: 600;
          margin: 0;
        }
        p {
          font-size: 12px;
          text-align: left;
        }
      }
    }
  }
  .finance {
    width: calc(100% - 32px);
    margin: 10px auto 0;
    height: 120px;
    padding: 26px 0 26px 26px;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    .text-main {
      width: 288px;
      .title {
        font-weight: 700;
        height: 16px;
        font-size: 12px;
        color: #fff;
        line-height: 12px;
        text-align: left;
      }
      .text {
        width: 288px;
        font-weight: 400;
        font-size: 12px;
        color: #fff;
        line-height: 18px;
        text-align: justify;
      }
    }
  }
  .case_wrap {
    width: 100%;
    //padding: 12px 0;
    .left-text {
      width: 100%;
      padding: 0 12px;
      & > div,
      & > h3 {
        width: 100%;
      }
      h3 {
        height: 17px;
        font-weight: 700;
        font-size: 12px;
        color: #030d1e;
        line-height: 17px;
        text-align: justify;
        margin: 0 0 8px;
      }
      div {
        font-weight: 400;
        font-size: 12px;
        height: 56px;
        color: #545558;
        line-height: 18px;
        text-align: justify;
      }
    }
  }
  .part.m-b-t.part-bg-2 {
    .box {
      width: 100%;
    }
    h4 {
      padding: 0 26px;
    }
  }
  .p-t-0{
    padding: 0;
  }
}
</style>
