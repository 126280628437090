import { render, staticRenderFns } from "./about.vue?vue&type=template&id=01311a9e&scoped=true"
import script from "./about.vue?vue&type=script&lang=js"
export * from "./about.vue?vue&type=script&lang=js"
import style0 from "./about.vue?vue&type=style&index=0&id=01311a9e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_2aqslel7prtkk4onzp5d3vudie/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01311a9e",
  null
  
)

export default component.exports