<template>
  <div class="container">
    <div class="solution_part" id="a">
      <div class="mobile-title">企业介绍</div>
      <a-row type="flex" class="introduce-warp">
        <a-col class="video-warp">
          <video
            src="@/assets/video/yuxin-info.mp4"
            class="video"
            loop
            muted
            autoplay
            preload
            controls="controls"
            x-webkit-airplay="true"
            airplay="allow"
            webkit-playsinline="true"
            playsinline="true"
          ></video>
        </a-col>
        <a-col class="mobile-warp">
          <h3>寓信科技 - 智能资管SaaS定制服务开创者</h3>
          <div>
            寓信科技，北京、深圳双总部并行，是一家专注于居住行业的科技互联网企业。业务领域涵盖保障性租赁住房、人才公寓、品牌公寓、园区宿舍、政府监管平台以及住房供应链平台等数字化解决方案。
            <br />
            服务客户包括中国银行、中粮集团、中海地产、中国联通、中兴发展、保利物业、东楚投资集团、太极股份、合景泰富、东方航空、格力集团、顺丰集团、上海新长宁、雄安集团、厦门地铁等央企国企及大型民营企业标杆客户。通过数百家头部企业的系统建设和运营经验沉淀，为企业构建一套完整的智慧公寓资产管理平台。
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="culture-warp">
      <div class="solution_part">
        <div class="title">企业文化</div>
        <div class="subtitle">
          寓信人有不忘初心、永不放弃的信念，因为相信，所以看见，因为坚持，所以长久。
        </div>

        <a-row :gutter="[35, 29]">
          <a-col :span="12" :md="12" :sm="24" :xs="24">
            <div class="culture-item">
              <div class="item-left">
                <img src="../../assets/image/culture1.png" alt="" />
                <p>使命</p>
              </div>
              <div class="culture-text">科技助力居住行业发展</div>
              <img
                src="../../assets/image/mission.png"
                class="bg"
                alt=""
                srcset=""
              />
            </div>
          </a-col>
          <a-col :span="12" :md="12" :sm="24" :xs="24">
            <div class="culture-item">
              <div class="item-left">
                <img src="../../assets/image/culture2.png" alt="" />
                <p>愿景</p>
              </div>
              <div class="culture-text">
                做最值得信任的长租公寓系统定制服务商
              </div>
              <img src="../../assets/image/wish.png" class="bg" />
            </div>
          </a-col>
          <a-col :span="12" :md="12" :sm="24" :xs="24">
            <div class="culture-item">
              <div class="item-left">
                <img src="../../assets/image/culture3.png" alt="" />
                <p>价值观</p>
              </div>
              <div class="culture-text">团结协作，诚信正直，创新发展！</div>
              <img src="../../assets/image/value.png" class="bg" />
            </div>
          </a-col>
          <a-col :span="12" :md="12" :sm="24" :xs="24">
            <div class="culture-item">
              <div class="item-left">
                <img src="../../assets/image/culture4.png" alt="" />
                <p>经营理念</p>
              </div>
              <div class="culture-text">
                以客户为中心，以奋斗进取者为本，持续地创新与发展
              </div>
              <img src="../../assets/image/concept.png" class="bg" />
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="relation-warp">
      <div class="solution_part">
        <div class="title padbot-41">联系我们</div>
        <a-row :gutter="[26, 29]">
          <a-col :span="12" :md="12" :sm="24" :xs="24">
            <div class="relation-item mar-top22">
              <div>
                <div class="relation-header">
                  <img
                    src="../../assets/image/location-icon.png"
                    class="icon1"
                    alt=""
                  />
                  <div>公司地址</div>
                </div>
                <div class="relation-text">
                  <span class="blue">全国总部：</span>
                  北京市石景山区点石商务公园6号楼9层
                </div>
                <div class="relation-text">
                  <span class="blue">研发中心：</span>
                  龙岗区坂田新天下工业城百瑞达大厦A2座2层
                </div>
              </div>
              <div class="relation-bottom">
                <span class="blue">分部：</span>
                上海 | 苏州 | 武汉 | 厦门 | 青岛 | 合肥
              </div>
            </div>
          </a-col>
          <a-col :span="12" :md="12" :sm="24" :xs="24">
            <div class="relation-item">
              <div>
                <div class="relation-header">
                  <img
                    src="../../assets/image/realation-icon2.png"
                    class="icon"
                    alt=""
                  />
                  <div>产品咨询</div>
                </div>
                <div class="relation-text">
                  如果您对我们的产品感兴趣，想深入了解、体验产品，请联系我们的产品咨询团队
                </div>
              </div>
              <div class="relation-bottom">
                联系电话：<span class="phone">199 2523 5826</span>
              </div>
            </div>
          </a-col>
          <a-col :span="12" :md="12" :sm="24" :xs="24">
            <div class="relation-item">
              <div>
                <div class="relation-header">
                  <img
                    src="../../assets/image/realation-icon3.png"
                    class="icon"
                    alt=""
                  />
                  <div>人才引进</div>
                </div>
                <div class="relation-text">
                  与寓信科技一起，用大数据和人工智能助力居住行业发展：
                </div>
              </div>
              <div class="relation-bottom">
                联系邮箱：<span class="blue2">hr@yxpms.com</span>
              </div>
            </div>
          </a-col>
          <a-col :span="12" :md="12" :sm="24" :xs="24">
            <div class="relation-item">
              <div>
                <div class="relation-header">
                  <img
                    src="../../assets/image/realation-icon2.png"
                    class="icon"
                    alt=""
                  />
                  <div>售后服务咨询</div>
                </div>
                <div class="relation-text">
                  如果您在使用产品的过程中有任何疑问、建议或意见，欢迎向我们提出：
                </div>
              </div>
              <div class="relation-bottom">
                联系邮箱：<span class="blue2">service@yxpms.com</span>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style lang="less" scoped>
.bg_wra {
  height: 480px;
  // line-height: 480px;
  padding: 146px 0 0 120px;
  color: #fff;
  background: url('../../assets/image/bg_abort.png') no-repeat center;
  background-size: 100% 100%;
  h1 {
    font-weight: 700;
    font-size: 50px;
    color: #fff;
  }
  h4 {
    color: #fff;
    font-weight: 700;
    font-size: 16px;
  }
}
.solution_part {
  width: 1200px;
  margin: 0 auto;
}
.title {
  font-weight: 500;
  color: #3d3d3d;
  font-size: 30px;
  padding-top: 70px;
}
.subtitle {
  font-weight: 400;
  color: #3d3d3d;
  font-size: 16px;
  margin: 24px 0;
}
.mobile-title {
  display: none;
}
.mobile-warp {
  & > div {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    color: #030d1e;
    line-height: 36px;
    text-align: justify;
  }
}

.nav-warp {
  border-bottom: 1px solid #eeeeee;

  .navigation {
    display: flex;
    height: 72px;
    line-height: 72px;
    padding: 0 120px;
    .navigation-item {
      font-size: 16px;
      margin-right: 70px;
      a {
        color: #3d3d3d;
      }
      a:hover {
        color: #1890ff;
      }
    }
  }
}
.introduce-warp {
  flex-wrap: nowrap;
  padding-bottom: 42px;
  background-color: #fff;
  .video {
    width: 442px;
    height: 256px;
    margin: 20px 48px 0 0;
  }
  h3 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
  }
  p {
    color: #3d3d3d;
    font-size: 16px;
  }
}
.culture-warp {
  background: #f6f7fb;
  padding-bottom: 86px;
  .culture-item {
    display: flex;
    align-items: center;
    color: #3d3d3d;
    font-size: 18px;
    background: #fff;
    padding: 34px 49px;
    position: relative;
    .bg {
      position: absolute;
      bottom: 0;
      left: 149px;
      // width: 208px;
      width: auto;
      height: 42px;
    }
    .item-left {
      margin-right: 45px;
    }
    img {
      height: 58px;
      width: 58px;
    }
    p {
      margin-top: 5px;
      text-align: center;
      color: #2d6ef6;
      font-size: 16px;
      white-space: nowrap;
    }
  }
}
.relation-warp {
  padding-bottom: 120px;
  .padbot-41 {
    padding-bottom: 41px;
  }
  .relation-item {
    padding: 37px 35px;
    background: #f2f4f8;
    height: 275px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .relation-header {
      display: flex;
      align-items: center;
      color: #3d3d3d;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 27px;
    }
    .relation-text {
      font-weight: 400;
      color: #3d3d3d;
      font-size: 16px;
      margin-top: 12px;
    }

    .icon1 {
      width: 25px;
      height: 35px;
      margin-right: 10px;
    }
    .icon {
      height: 28px;
      width: 28px;
      margin-right: 10px;
    }
    .relation-bottom {
      margin-top: 40px;
    }
  }
}

@media screen and (max-width: 768px) {
  .solution_part {
    width: 100%;
    .title {
      font-weight: 500;
      color: #030d1e;
      font-size: 23px;
      padding-bottom: 16px;
      margin: 0 0 0 20px;
      position: relative;
      padding-top: 30px;
    }
    .title::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 30px;
      border: 2px solid #2d6ef6;
    }
    .subtitle {
      color: #404548;
      font-size: 15px;
    }
  }
  .nav-warp {
    .navigation {
      width: 100%;
      height: 46px;
      line-height: inherit;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 32px;
      .navigation-item {
        margin-right: 0;
      }
    }
  }
  .culture-warp {
    padding: 0 20px 22px 20px;
    .culture-item {
      padding: 26px 16px;
      align-items: flex-start;
      flex-direction: column;
      .culture-text {
        padding-left: 40px;
        font-size: 14px;
        color: #404548;
      }
      .item-left {
        display: flex;
        align-items: center;
        margin: 0 0 10px;
        img {
          height: 24px;
          width: 24px;
        }
      }
      p {
        margin: 0;
        margin-left: 16px;
        font-weight: 600;
        color: #2d6ef6;
        font-size: 17px;
      }
      .bg {
        left: 66px;
      }
    }
  }

  .introduce-warp {
    position: relative;
    display: block;
    .video-warp {
      width: calc(100% - 32px);
      margin: 16px auto;
      height: 198px;
      border-radius: 0px;
      .video {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }
    .mobile-warp {
      padding: 0 20px;
      text-align: justify;
      h3 {
        font-size: 15px;
      }
      & > div {
        color: #030d1e;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: left;
        text-align: justify;
      }
      p {
        font-size: 14px;
      }
      .hint {
        font-weight: 600;
        color: #2d6ef6;
      }
    }
  }
  .introduce-warp::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 56px;
    width: 260px;
    height: 36px;
    background-image: url('../../assets/image/about-mobile.png');
    background-size: 100% 100%;
  }
  .mobile-title {
    display: block;
    font-weight: 500;
    color: #030d1e;
    font-size: 23px;
    padding-bottom: 16px;
    margin: 30px 0 0 20px;
    position: relative;
  }
  .mobile-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30px;
    border: 2px solid #2d6ef6;
  }
  .relation-warp {
    margin: 0 20px;
    padding-bottom: 60px;
    .mar-top22 {
      margin-top: 22px;
    }
    .relation-item {
      padding: 26px 16px;
      height: auto;
      .relation-header {
        color: #030d1e;
        font-weight: 600;
        font-size: 17px;
        margin-bottom: 19px;
      }
      .relation-text {
        font-size: 14px;
        margin-top: 6px;
      }
      .blue {
        font-weight: 700;
        color: #0266ff;
      }
      .blue2 {
        font-weight: 700;
        color: #0266ff;
        font-size: 16px;
      }
      .phone {
        font-size: 16px;
        color: #404548;
        font-weight: 700;
      }
      .relation-bottom {
        font-size: 14px;
      }
      .icon1 {
        height: 26px;
        width: 22px;
      }
    }
  }
  .bg_wra {
    height: 260px;
    background: url('../../assets/image/about-bg.png') no-repeat center;
    background-size: 100% 100%;
    padding: 94px 20px 0;
    h1 {
      font-size: 26px;
    }
    h4 {
      font-size: 14px;
    }
  }
}
</style>
